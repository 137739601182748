import React, { useState } from "react"
import { Button, Input, Dropdown, Row, Col, notification, Radio } from "antd"
import type { MenuProps } from 'antd';
import { applicationItems, colorItems, downloadImage, iconographyItems, strokeItems, styleItems, termsItems } from "../utils";
import { generateFromOption, generateIcons } from "../@service";
import { ImageSvg, Loader, Modal } from "../components";
import { Toast } from "../components";
import { toast } from "react-toastify";
import { CloseCircleOutlined, DownOutlined, DownSquareOutlined } from "@ant-design/icons";
import "../assets/style/main.css"


type TGeneratorProps = {
    user_input: string;
    image_type: "icon" | "image" | "striker" | "logo";
}

type Data = {
    loading: boolean;
    response: Record<string, any> | null;
}

const NewIconPack = () => {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [data, setData] = useState<Data>({ loading: false, response: null });
    const [generatorProps, setGeneratorProps] = useState<TGeneratorProps | any>({
        user_input: "",
        image_type: "",
    })

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item && item !== '') {
            setGeneratorProps({ ...generatorProps, [item]: e.target.value })
        }
        setErrors({})
    }

    const submitOnClick = async () => {
        const newErrors: any = {};
        Object.keys(generatorProps).forEach(key => {
            if (!generatorProps[key]) {
                newErrors[key] = 'This field is required';
            }
        });
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            await fetchIconData()
        }
    };

    const fetchIconData = async () => {
        setData({ ...data, loading: true });
        await generateFromOption({ data: generatorProps }).then(async (res: any) => {
            if (res.status === 200) {
                let jsonData = res?.data;
                console.log(jsonData);
                notification.success({ message: "Success", description: "Icon generated successfully.", placement: "topRight" });
                setData({ loading: false, response: jsonData });
            } else {
                console.error('Error fetching data:', res.response);
                setData({ ...data, loading: false });
            }
        })
    }

    const handleImageTypeChange = (e: any) => {
        setGeneratorProps({ ...generatorProps, image_type: e.target.value });
    };

    return (
        <React.Fragment>
            {data.loading ? <Loader /> : null}
            <div className="container">
                <div className="left-modal" />
                <div className="iconGeneratorWrapper">

                    <div className="fldWrap">
                        <Input
                            onChange={(e) => handleInputChange(e, 'user_input')}
                            placeholder="User Input"
                        />
                        <div className="hint">Please enter a keyword or description for the icon you want to generate.</div>
                        {errors['user_input'] && <p className="error">{errors['user_input']}</p>}
                    </div>

                    <div className="fldWrap">
                        <Radio.Group
                            onChange={handleImageTypeChange}
                            value={generatorProps.image_type}
                        >
                            <Radio value="icon">Icon</Radio>
                            <Radio value="image">Image</Radio>
                            <Radio value="striker">Striker</Radio>
                            <Radio value="logo">Logo</Radio>
                        </Radio.Group>
                        {errors["image_type"] && <p className="error">{errors["image_type"]}</p>}
                    </div>

                    <div className="btnWrap">
                        <Button className="btn-primary flex items-center " onClick={submitOnClick}>Submit</Button>
                    </div>
                </div>
                {data?.response?.image_url ?
                    <div className="right-modal">
                        <React.Fragment>
                            <Modal image={data?.response?.image_url} data={data?.response} />
                            <div onClick={() => downloadImage(data?.response?.image_url)}>
                                <div className="down-label">Download</div>
                                <DownSquareOutlined />
                            </div>
                        </React.Fragment> :
                        {!data?.response?.image_url && <React.Fragment>
                            <div className="hint flex flex-col items-center justify-center image-svg">
                                <ImageSvg />
                                Icon will be generated here.
                            </div>
                        </React.Fragment>}
                    </div>
                    : <div className="left-modal"></div>}

            </div>
            <Toast />
        </React.Fragment>
    )
}

export default NewIconPack