import { AxiosError, AxiosResponse } from "axios"
import { service } from "./intercepor"


export const generateIcons = async (params: any) => {

    let url = `/api/v1/icon-pack-creation`

    return service
        .post(url, params)
        .then((res: AxiosResponse) => res)
        .catch((err: AxiosError) => err)
}

export const generateFromOption = async (params: any) => {

    let url = `/api/v1/generate-image-from-raw-text`

    return service
        .post(url, params)
        .then((res: AxiosResponse) => res)
        .catch((err: AxiosError) => err)
}