import React, { useState } from "react"
import { Button, Input, Dropdown, Row, Col, notification } from "antd"
import type { MenuProps } from 'antd';
import { applicationItems, colorItems, downloadImage, iconographyItems, strokeItems, styleItems, termsItems } from "../utils";
import { generateIcons } from "../@service";
import { ImageSvg, Loader, Modal } from "../components";
import { Toast } from "../components";
import { toast } from "react-toastify";
import { CloseCircleOutlined, DownOutlined, DownSquareOutlined } from "@ant-design/icons";
import "../assets/style/main.css"


type TGeneratorProps = {
    theme?: string;
    style?: string;
    application?: string;
    color_palette?: string;
    stroke_width?: string;
    iconography_style?: string;
    terms_details?: string[];
    user_input: string;
}

type Data = {
    loading: boolean;
    response: Record<string, any> | null;
}

const IconPack = () => {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [data, setData] = useState<Data>({ loading: false, response: null });
    const [activeDropdownItem, setActiveDropdownItem] = useState<any>({
        key: false,
        value: false
    })
    const [generatorProps, setGeneratorProps] = useState<TGeneratorProps | any>({
        theme: "",
        style: "vivid",
        // application: "",
        color_palette: "",
        stroke_width: "",
        // iconography_style: "",
        // terms_details: [],
        // user_input: "",
    })
    const [imageUrl, setImageUrl] = useState<string>('');

    const items: MenuProps['items'] = [
        {
            label: "Dropdown Item 1",
            key: "1",
        },
        {
            label: "Dropdown Item 2",
            key: "2",
        },
        {
            label: "Dropdown Item 3",
            key: "3",
        },
        {
            label: "Dropdown Item 4",
            key: "4",
        },
        {
            label: "Dropdown Item 5",
            key: "5",
        },
    ];


    const handleDropdownButtonClick = (e: any, item: string) => {
        if (item) {
            setActiveDropdownItem({
                key: item
            })
        }
    };

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        const value: string | any = e.domEvent.currentTarget.textContent;
        const updatedProps: any = { ...generatorProps };
        if (activeDropdownItem.key === "terms_details") {
            // updatedProps.terms_details = [
            //     // ...updatedProps.terms_details,
            //     value
            // ];
        } else {
            updatedProps[activeDropdownItem.key] = value;
        }
        setGeneratorProps(updatedProps);
        setErrors({ ...errors, [activeDropdownItem.key]: value.trim() === '' ? 'This field is required' : '' });
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, item: string) => {
        if (item && item !== '') {
            setGeneratorProps({ ...generatorProps, [item]: e.target.value })
        }
        setErrors({})
    }

    const submitOnClick = async () => {
        const newErrors: any = {};
        Object.keys(generatorProps).forEach(key => {
            if (!generatorProps[key]) {
                newErrors[key] = 'This field is required';
            }
        });

        // Validate terms_details field if it's an empty array
        // if (generatorProps.terms_details && generatorProps.terms_details.length === 0) {
        //     newErrors.terms_details = 'At least one item is required';
        // }
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            await fetchIconData()
        }
    };

    const fetchIconData = async () => {
        setData({ ...data, loading: true });
        await generateIcons({ data: generatorProps }).then(async (res: any) => {
            if (res.status === 200) {
                let jsonData = res?.data;
                console.log(jsonData);
                notification.success({message: "Icon generated successfully.", placement: "topRight"});
                setImageUrl(jsonData?.image_url)
                setData({ loading: false, response: jsonData });
            } else {
                console.error('Error fetching data:', res.response);
                setData({ ...data, loading: false });
            }
        })
    }

    // const menuProps = {
    //     items,
    //     onClick: handleMenuClick,
    // };

    const styleProps = {
        items: styleItems,
        onClick: handleMenuClick
    }

    const applicationProps = {
        items: applicationItems,
        onClick: handleMenuClick
    }

    const colorProps = {
        items: colorItems,
        onClick: handleMenuClick
    }

    const strokeProps = {
        items: strokeItems,
        onClick: handleMenuClick
    }

    const iconographyProps = {
        items: iconographyItems,
        onClick: handleMenuClick
    }


    // const termsProps = {
    //     items: termsItems.map(item => ({
    //         ...item,
    //         disabled: (generatorProps as any).terms_details.includes(item.label),
    //         // icon: ((generatorProps as any).terms_details.includes(item.label)) ? <CloseCircleOutlined onClick={() => removeTerms(item)} /> : null
    //     })),
    //     onClick: handleMenuClick
    // }

    // const removeTerms = (item:  { label: string; key: string }) => {
    //     const { terms_details } = generatorProps
    //     const filteredTerms = terms_details.filter((elem: string) => elem !== item.label)
    //     setGeneratorProps((prevState: TGeneratorProps) => ({
    //         ...prevState,
    //         terms_details: filteredTerms
    //     }));
    // }


    // useEffect(() => {
    //     console.log(activeDropdownItem.key)
    //     console.log(activeDropdownItem.value)
    // }, [activeDropdownItem.value])

    // useEffect(() => {
    //     console.log(generatorProps)
    // }, [generatorProps])

    return (
        <React.Fragment>
            {data.loading ? <Loader /> : null}
            <div className="container">
                {/* <div className="left-modal" /> */}
                <div className="iconGeneratorWrapper">
                    <div className="fldWrap">
                        <Input
                            onChange={(e) => handleInputChange(e, 'theme')}
                            placeholder="Theme"
                        />
                        <div className="hint">Eg: Italian, Indian Food, Singaporian Theme, Chinese Dishes</div>
                        {errors['theme'] && <p className="error">{errors['theme']}</p>}
                    </div>
                    <div className="fldWrap">
                        <Dropdown
                            trigger={['click']}
                            menu={styleProps}
                            onOpenChange={(e) => handleDropdownButtonClick(e, 'style')}
                            className="dropdown">
                            <Button>
                                <span className="placeholder">
                                    Style:
                                </span>{(generatorProps.style) ? `${generatorProps.style}` : ''}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        {errors['style'] && <p className="error">{errors['style']}</p>}
                    </div>
                    {/* <div className="fldWrap">
                        <Dropdown
                            trigger={['click']}
                            menu={applicationProps}
                            onOpenChange={(e) => handleDropdownButtonClick(e, 'application')}
                            className="dropdown">
                            <Button>
                                <span className="placeholder">
                                    Application:
                                </span>{(generatorProps.application) ? `${generatorProps.application}` : ''}
                                <DownOutlined />
                            </Button>

                        </Dropdown>
                        {errors['application'] && <p className="error">{errors['application']}</p>}
                    </div> */}
                    <div className="fldWrap">
                        <Dropdown
                            trigger={['click']}
                            menu={colorProps}
                            onOpenChange={(e) => handleDropdownButtonClick(e, 'color_palette')}
                            className="dropdown">
                            <Button>
                                <span className="placeholder">
                                    Color Palette:
                                </span>{(generatorProps.color_palette) ? `${generatorProps.color_palette}` : ''}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        {errors['color_palette'] && <p className="error">{errors['color_palette']}</p>}
                    </div>
                    <div className="fldWrap">
                        <Dropdown
                            trigger={['click']}
                            menu={strokeProps}
                            onOpenChange={(e) => handleDropdownButtonClick(e, 'stroke_width')}
                            className="dropdown">
                            <Button>
                                <span className="placeholder">
                                    Stroke Width:
                                </span> {(generatorProps.stroke_width) ? `${generatorProps.stroke_width}` : ''}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        {errors['stroke_width'] && <p className="error">{errors['stroke_width']}</p>}
                    </div>
                    {/* <div className="fldWrap">
                        <Dropdown
                            trigger={['click']}
                            menu={iconographyProps}
                            onOpenChange={(e) => handleDropdownButtonClick(e, 'iconography_style')}
                            className="dropdown">
                            <Button>
                                <span className="placeholder">
                                    Iconography:
                                </span> {(generatorProps.iconography_style) ? `${generatorProps.iconography_style}` : ''}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        {errors['iconography_style'] && <p className="error">{errors['iconography_style']}</p>}
                    </div> */}
                    {/* <div className="fldWrap">
                        <Dropdown
                            trigger={['click']}
                            menu={termsProps}
                            onOpenChange={(e) => handleDropdownButtonClick(e, 'terms_details')}
                            className="dropdown">
                            <Button>
                                <span className="placeholder">
                                    Terms:
                                </span>{(generatorProps.terms_details) ? ` ${generatorProps.terms_details}` : ''}
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                        {errors['terms_details'] && <p className="error">{errors['terms_details']}</p>}
                    </div> */}
                    {/* <div className="fldWrap">
                        <Input
                            onChange={(e) => handleInputChange(e, 'user_input')}
                            placeholder="User Input"
                        />
                        <div className="hint">Please enter a keyword or description for the icon you want to generate.</div>
                        {errors['user_input'] && <p className="error">{errors['user_input']}</p>}
                    </div> */}
                    <div className="btnWrap">
                        <Button className="btn-primary flex items-center " onClick={submitOnClick}>Submit</Button>
                    </div>
                </div>

            </div>
            <Row gutter={[16, 24]}>
                {data?.response && data?.response?.length > 0 ?
                    data?.response.map((item: any, index: any) => (
                        <Col className="gutter-row" span={6} key={index.toString()}>
                            <div className="right-modal">
                                {item?.image_url ?
                                    <React.Fragment>
                                        <Modal image={item?.image_url} />
                                        <div onClick={() => downloadImage(item?.image_url)}>
                                            <div className="down-label">Download</div>
                                            <DownSquareOutlined />
                                        </div>
                                    </React.Fragment> :
                                    <React.Fragment>
                                        <div className="hint flex flex-col items-center justify-center image-svg">
                                            <ImageSvg />
                                            Icon will be generated here.
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </Col>
                    )) : <div className="left-modal"></div>}
            </Row>
            <Toast />
        </React.Fragment>
    )
}

export default IconPack