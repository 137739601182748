import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import siteLogo from "../assets/images/logo.png";
// import siteLogo from "../assets/images/data-cata.jpg";
import { Dropdown, Button } from "antd";
import { MenuProps } from "antd/lib/menu";
import "../assets/scss/custom-style.css";

type TabItem = {
    tab: string;
    route: string;
};

const ResumeHeader = () => {

    const location = useLocation();
    const currentPath = location.pathname;
    const [activeTab, setActiveTab] = useState(currentPath);
    const items: MenuProps["items"] = [
        {
            label: <Button type="primary" danger onClick={() => { console.log("hi") }}>Logout!</Button>,
            key: "0",
        },
    ];

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName); // Set the active tab when clicked
    };

    const extractEmailPrefix = (email: string) => {
        if (email.includes(".")) {
            return email.split(".")[0];
        } else if (email.includes("@")) {
            return email.split("@")[0];
        } else {
            return "Not found!";
        }
    }

    function capitalizeFirstLetter(str: string) {
        // Check if the input string is not empty
        if (str.length === 0) {
            return str;
        }
        // Capitalize the first letter and concatenate it with the rest of the string
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const render_fn = () => {
        if (localStorage.getItem("wiaUser")) {

            let userPayload: any = localStorage.getItem("wiaUser")
            let emailAddress = JSON.parse(userPayload)?.email
            if (emailAddress) {
                const user_name = extractEmailPrefix(emailAddress)
                const formatted = capitalizeFirstLetter(user_name)

            }
        }
    };

    const tabs: TabItem[] = [
        { tab: "Data Analysis", route: "/data-analysis-with-file" },
        { tab: "Icon Generator", route: "/icon-pack" },
        { tab: "Image Generator", route: "/new-icon-pack" }
    ]


    useEffect(() => {
        render_fn();
    },);

    return (
        <div className="pageContainer siteHeader flex items-center">
            <div className="siteLogo">
                <img src={siteLogo} alt="Data Categorization" />
            </div>
            <div className="flex-1 flex items-center justify-center">
                <div className="tabsMenu">
                    {tabs.map((item: TabItem) => (
                        <Link
                            key={item.tab}
                            className={`tab-item ${activeTab === item?.route ? "active" : ""}`}
                            to={item?.route}
                            onClick={() => handleTabClick(item?.route)}
                        >{item?.tab}</Link>
                    ))}
                </div>
            </div>
            <div className="userDropdown">
                <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomRight"
                    className="dropdown"
                >
                    <a onClick={(e) => e.preventDefault()} className="flex items-center">
                        {/* <span>{userName}</span> */}
                        <span>Digital Avenues</span>
                        {/* <span className="material-symbols-outlined">expand_more</span> */}
                    </a>
                </Dropdown>
            </div>
        </div>
    );
};

export default ResumeHeader;
