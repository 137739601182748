export const downloadImage = (imageUrl: string) => {
    if (!imageUrl) {
        console.error('No signed URL available for download');
        return;
    }

    fetch(imageUrl)
        .then(response => response.blob())
        .then(blob => {
            const blobUrl = URL.createObjectURL(blob);
            const downloadAnchor = document.createElement('a');
            downloadAnchor.style.display = 'none';
            downloadAnchor.href = blobUrl;
            downloadAnchor.download = 'downloaded_image.png'; // Set the filename for the downloaded image
            document.body.appendChild(downloadAnchor);
            downloadAnchor.click();

            document.body.removeChild(downloadAnchor);
            URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
            console.error('Error downloading image:', error);
        });
}